<template>
  <div class="
        flex flex-col
        w-full
        bg-lightGrey
        rounded-xl
        text-grey
        justify-center
      ">
    <div class="w-3/4 flex flex-col m-auto gap-y-5 mb-3">
      <span class="self-start text-xl font-bold mt-5">{{ title }}</span>
      <input class="border rounded-md p-1 col-span-2" type="date" v-model="scheduleInterviewData.date" :class="
        v$.scheduleInterviewData.date.$error === true
          ? 'border-red'
          : 'border-grey'
      " />
      <span class="text-xs text-red" v-if="v$.scheduleInterviewData.date.$error">
        {{ v$.scheduleInterviewData.date.minValue.$message }}
      </span>
      <input class="border rounded-md p-1 col-span-2" type="time" name="interviewTime"
        v-model="scheduleInterviewData.time" :class="
          v$.scheduleInterviewData.time.$error === true
            ? 'border-red'
            : 'border-grey'
        " />
      <select name="duration" v-model="scheduleInterviewData.duration" id="duration-select"
        class="border rounded-md p-1 col-span-2" :class="
          v$.scheduleInterviewData.duration.$error === true
            ? 'border-red'
            : 'border-grey'
        ">
        <option value="0" disabled selected>--Please choose interview duration--</option>
        <option value="15 minutes">15 minutes</option>
        <option value="30 minutes">30 minutes</option>
        <option value="45 minutes">45 minutes</option>
        <option value="1 hour">1 hour</option>
        <option value="2 hours">2 hours</option>
        <option value="2.5 hours">2 hours 30 minutes</option>
      </select>
    </div>
    <div class="flex justify-center items-center">
      <base-button backgroundColour="bg-orange hover:bg-orange-light" @click="submit">Confirm</base-button>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/ui/baseComponents/BaseButton.vue";
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
export default {
  components: { BaseButton },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      v$: useVuelidate(),
      scheduleInterviewData: {
        date: null,
        time: null,
        duration: null,
      },
      practiceData: [],
    };
  },
  async created() {
    await this.loadPractices();
  },
  validations() {
    return {
      scheduleInterviewData: {
        date: {
          required,
          minValue: helpers.withMessage("Required (Date should not be less than today.)", value => value >= new Date((new Date()).valueOf() - 1000*60*60*24).toISOString()),
        },
        time: { required },
        duration: { required },
      },
    };
  },
  methods: {
    submit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.$emit("submit-form", this.scheduleInterviewData);
      }
    },
    async loadPractices() {
      try {
        await this.$store.dispatch("admin/loadPractices");
      } catch (error) {
        this.error = error.message || "Something went wrong!";
      }
    },
  },
};
</script>

<style>
.filterDropDown .vs__dropdown-toggle,
.filterDropDown .vs__dropdown-menu {
  border: 1px solid;
  border-radius: 0.5rem;
  background: white;
  padding-left: 0.2rem;
}

.filterDropDown .vs__dropdown-toggle {
  height: 2.5rem;
  width: 100%;
}

#filterDropDown .filterDropDown .vs__clear,
#filterDropDown .filterDropDown .vs__open-indicator {
  fill: black;
}

.filterDropDown-error .vs__dropdown-toggle,
.filterDropDown-error .vs__dropdown-menu {
  border: 1px solid red;
  border-radius: 0.5rem;
  background: white;
  padding-left: 0.2rem;
}

.filterDropDown-error .vs__dropdown-toggle {
  width: 100%;
}

#filterDropDown-error .filterDropDown .vs__clear,
#filterDropDown-error .filterDropDown .vs__open-indicator {
  fill: black;
}
</style>
